import React from 'react';
import {query} from '../../actions/queries';
import {useQuery} from '@apollo/client';
import Button from '../../components/ui/Button';
import {useHistory} from 'react-router-dom';

export const Questions=()=>{
	const history=useHistory();
	const {loading,error,data}=useQuery(query('questionsFindMany'),{variables:{
		sort :'CREATEDAT_DESC'
	}});
	
	if(loading) return <p>Loading...</p>;
	
	if(error) return <p>Error</p>;
	return (
		<div className='bg-background-light dark:bg-background-dark'>
			<div className='flex items-center'>
				<h4 className='shrink font-sans mr-2 text-white'>Questions</h4>
				<Button type={'Link'} to={'question/new'}>Create</Button>
				<div className='grow' />
				<div className='shrink'>
					<p className='font-sans text-white'>filters</p>
				</div>
			</div>
			<table className='mt-4'>
				<thead className='font-sans font-bold uppercase text-sm'>
					<tr>
						<td className='h-12 border-b border-t border-gray-200 text-white pl-1'>
						Title
						</td>
					</tr>
				</thead>
				<tbody>
					{data && data.QuestionsFindMany.map(
						({ label, _id }) => (
							<tr
								key={_id}
								id={_id}
								onClick={() => history.push(`/question/${_id}`)}
								className='hover:bg-button-hover-light dark:hover:bg-button-hover-dark cursor-pointer'
							>
								<td className='py-2 border-b border-gray-200'>
									<div className='flex items-center p-3'>
										<h3 className='shrink h5 font-bold text-white'>
											{label}
										</h3>
									</div>
								</td>
							</tr>
						)
					)}
				</tbody>
			</table>
		</div>
	);
};