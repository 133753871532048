import PropTypes from 'prop-types';
import {LifeSkillFindMany, SkillSetFindMany, ThemesFindMany} from '../../../../hoc';
import {CaptionsInput, CheckBox, ImageInput, TagsSelector, TextArea} from '../../../../components';
import {UsersFindMany} from '../../../../hoc';
import React, {useEffect, useRef, useState} from 'react';
import {LabelVideoContainer, VideoElement, VideoWrapper} from '../../../Courses/CoursesCreate/CoursesCreate.style';
import {PlayIcon} from '../../../../icons/svg/PlayIcon';
import Input from '../../../../components/ui/Input';
import Select from '../../../../components/ui/Select';

export const InfoRender = ({
	formState,
	selectedLifeSkills,
	selectedSkillSets,
	selectedThemes,
	curriculum,
	handleChange,
	handleFileChange,
	handleTrailerChange,
	handleLifeSkillsChange,
	handleSkillSetsChange,
	handleThemesChange,
	handleSubmit,
	trailerPreview,
	isLoading,
	isError,
	subtitles,
	handleCheckboxChange,
	changed
}) => {
	const [isChanged, setIsChanged] = useState(false);
	const isButtonDisabled = !(isChanged || changed) || isLoading ;
	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.load(); // Recarga el video cuando cambia el src
		}
	}, [trailerPreview]);

	return (
		<form
			onChange={()=>setIsChanged(true)}
			className={'p-5 w-100  justify-start align-center gap-7'}
			onSubmit={handleSubmit}
		>
			<div className={'grid grid-cols-2 gap-4'}>

				<ImageInput
					className={'rounded-md overflow-hidden'}
					defaultImage={curriculum?.cover?.url}
					onChange={handleFileChange}
				/>
				<div className={'flex-grow'}>
					<Input
						label={'Title'}
						type='text'
						name='title'
						value={formState.title}
						onChange={handleChange}
					/>
					<TextArea
						label={'Description'}
						name='description'
						value={formState.description}
						onChange={handleChange}
					/>
					<UsersFindMany
						filter={{type:'a4f84644-9b6f-11ee-ab20-325096b39f47'}} // type : tutor
					>
						{users => (
							<Select
								label={'Teacher'}
								name={'teacher'}
								defaultValue={formState.teacher}
								options = {users.map(user=>({
									label:user.firstName + ' '+ user.lastName,
									value:user._id
								}))}
								onChange={handleChange}
							/>
						)}
					</UsersFindMany>

				</div>

			</div>
			<div className={'w-100 '}>
				<CheckBox
					name={'mini'}
					label={'Mini course'}
					defaultChecked={formState.mini}
					onChange={handleCheckboxChange}
				/>
				<CheckBox
					name={'active'}
					label={'Curriculum enabled'}
					defaultChecked={formState.active}
					onChange={handleCheckboxChange}
				/>

				<CheckBox
					name={'donation'}
					label={'Curriculum Donations enabled'}
					defaultChecked={formState.donation}
					onChange={handleCheckboxChange}
				/>


			</div>

			<div className={'grid grid-cols-2 gap-4'}>
				<Input
					label={'Slug'}
					type='text'
					name='slug'
					value={formState.slug}
					onChange={handleChange}
				/>
				<Input
					type='text'
					label={'Course Duration'}
					name='courseDuration'
					value={formState.courseDuration}
					onChange={handleChange}
				/>
				<Input
					type='text'
					label={'Lesson Length'}
					name='lessonLength'
					value={formState.lessonLength}
					onChange={handleChange}
				/>
				<Input
					type='number'
					label={'Total Lessons'}
					name='totalLessons'
					value={formState.totalLessons}
					onChange={handleChange}
				/>

				<Input
					label='Category'
					type='text'
					name='animalCategory'
					value={formState.animalCategory}
					onChange={handleChange}
				/>


			</div>
			<TextArea
				label='Leader Bio'
				name='leaderBio'
				value={formState.leaderBio}
				onChange={handleChange}
			/>
			<TextArea
				label={'Learning Goal'}
				name='learningGoal'
				value={formState.learningGoal}
				onChange={handleChange}
			/>

			<div className='flex justify-center align-center self-center pt-2'>
				<div className='mb-4 flex direction-column gap-2 justify-center align-center'>
					<LabelVideoContainer htmlFor='trailer-upload'>
						<div className={'w-full h-full relative rounded'}>
							{trailerPreview ? (
								<video
									controls
									ref={videoRef}
									className='w-full object-cover aspect-square cursor-pointer rounded border border-text-dark'
								>
									<source src={trailerPreview}/>
									{subtitles && subtitles.map((subtitle, index) => (
										<track
											key={index}
											label={subtitle.label || 'Subtitles'}
											kind='subtitles'
											srcLang={subtitle.language || 'en'}
											src={subtitle.url}
											default={index === 0}
										/>
									))}
								</video>
							) : (
								<div
									className='w-100 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'>
									<div className='flex justify-center align-center p-2 w-44 h-44 overflow-hidden'>
										<PlayIcon width='3em' height='3em' className='cursor-pointer'/>
									</div>
								</div>
							)}
						</div>
						<span
							className='font-sans f-600 mt-3 text-center text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'>
							{trailerPreview ? 'Edit Trailer' : 'Upload Trailer'}
						</span>
						<input
							id='trailer-upload'
							type='file'
							accept='video/mp4'
							onChange={handleTrailerChange}
							className='hidden'
						/>
					</LabelVideoContainer>
				</div>
			</div>

			<TagsSelector
				selectedTags={selectedLifeSkills}
				setSelectedTags={handleLifeSkillsChange}
				HOC={LifeSkillFindMany}
				label='Life Skills'
			/>
			<TagsSelector
				selectedTags={selectedSkillSets}
				setSelectedTags={handleSkillSetsChange}
				HOC={SkillSetFindMany}
				label='Skill Sets'
			/>
			<TagsSelector
				selectedTags={selectedThemes}
				setSelectedTags={handleThemesChange}
				HOC={ThemesFindMany}
				label='Themes'
			/>
			<div className={'mt-4 mb-4 flex direction-column align-center justify-between gap-2'}>
				<p className={'font-mono text-text-light dark:text-text-dark'}>
					<b>Created At: </b>{new Date(curriculum?.createdAt).toLocaleString()}
				</p>
				<p className={'font-mono text-text-light dark:text-text-dark'}>
					<b>Updated At: </b>{new Date(curriculum?.updatedAt).toLocaleString()}
				</p>
			</div>
			<div className={'flex align-middle justify-center center'}>
				<button
					onClick={handleSubmit}
					className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
						isButtonDisabled? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
					} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
					disabled={isButtonDisabled}
				>
					{isLoading ? 'Loading...' : 'Update'}
				</button>
				{isError && <p className={'text-red-500'}>{isError.message ? isError.message : 'An error occurred'}</p>}
			</div>

		</form>
	);
};

InfoRender.propTypes = {
	formState: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		teacher: PropTypes.string,
		slug: PropTypes.string,
		animalCategory: PropTypes.string,
		leaderBio: PropTypes.string,
		learningGoal: PropTypes.string,
		active: PropTypes.bool.isRequired,
		mini: PropTypes.bool.isRequired,
	}).isRequired,
	curriculum: PropTypes.shape({
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired
	}).isRequired,
	subtitleState: PropTypes.shape({
		file: PropTypes.string,
		error: PropTypes.string,
		loading: PropTypes.bool
	}),
	selectedLifeSkills: PropTypes.array.isRequired,
	selectedSkillSets: PropTypes.array.isRequired,
	selectedThemes: PropTypes.array.isRequired,

	handleChange: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	handleTrailerChange: PropTypes.func.isRequired,
	handleLifeSkillsChange: PropTypes.func.isRequired,
	handleSkillSetsChange: PropTypes.func.isRequired,
	handleThemesChange: PropTypes.func.isRequired,
	handleCheckboxChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isError: PropTypes.object,
	subtitles: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		language: PropTypes.string,
		url: PropTypes.string
	})),
	refetch: PropTypes.func.isRequired,
};