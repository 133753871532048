import {query} from '../../actions/queries';
import {useQuery} from '@apollo/client';
import {BadgesRender} from './BadgesRender';
import {Loader} from '../../components';

export const BadgesLoad = () => {
	const { loading, error, data, refetch } = useQuery(query('badgesFindMany'), {
		nextFetchPolicy: 'network-only'
	});

	if (loading) return <div><Loader/></div>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;
	if (!data || !data.BadgesFindMany) return <p className={'font-mona f-900 white p-3'}>No Data</p>;

	return <BadgesRender badges={data ? data.BadgesFindMany : []} />;
};