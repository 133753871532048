import gql from 'graphql-tag';

const queries = {
	organizationsFindMany: gql`
		query OrganizationsFindMany($filter: FilterFindManyorganizationInput, $skip: Int, $limit: Int, $sort: SortFindManyorganizationInput){
            OrganizationsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                name
                code
                deletedAt
                createdAt
                updatedAt
                lockedAt
                cover {
                    url
                    type
                }
            }
		}
	`,
	districtFindMany: gql`
		query DistrictFindMany($filter: FilterFindManydistrictInput, $skip: Int, $sort: SortFindManydistrictInput){
			  DistrictFindMany(filter: $filter, skip: $skip, sort: $sort) {
			  	_id
				name
				state
				country
				organization
				createdAt
				updatedAt
			  }
		}
	`,
	districtFindOne: gql`
		query DistrictFindOne($filter: FilterFindOnedistrictInput, $skip: Int, $sort: SortFindOnedistrictInput){
			DistrictFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                name
                state
                country
                organization
                createdAt
                updatedAt
			}
		}
	`,
	UsersByOrganizationFindMany: gql`
        query UsersByOrganizationFindMany($organization: String!, $type: String!){
            UsersByOrganizationFindMany(organization: $organization, type: $type) {
                users {
                    _id
                    id
                    firstName
                    lastName
                    userName
                    tag
                    bio
                    birthday
                    email
                    profilePicture{
                        type
                        url
                        title
                    }
                    points
                    type
                    lastLogin
                    organization
                    cleverId
                    cleverToken
                    classLinkId
                    cleverSync
                    classLinkSync
                    deedlyAuthenticationToken
                    deedlyUserId
                    createdAt
                    updatedAt
                }
                total
            }
		}
	`,
	OrganizationUserHistoryFindMany: gql`
        query OrganizationUserHistoryFindMany(
            $organization: String
            $type: String       # userType
            $dateField: String! # createdAt | lastLogin
            $startDate: String
            $endDate: String
        ){
            OrganizationUserHistoryFindMany(
                organization: $organization
                type: $type 
                dateField: $dateField
                startDate: $startDate
                endDate: $endDate
            ) {
                date
                count
            }
        }
    `,
	GroupFindMany: gql`
		query GroupFindMany($filter: groupsInput){
			GroupFindMany(
				filter: $filter
			){
                _id
                manager
                name
                school
                grade
                code
                deleted
                classes {
                    unLock
                    id
                }
                deletedAt
                organization
                classLink
                curriculums
                createdAt
                updatedAt
			}
		}
	`,
	UsersFindManyInDistrict: gql`
        query UsersFindMany($filter: FilterFindManyusersInput, $limit: Int, $skip: Int, $sort: SortFindManyusersInput!) {
            UsersFindMany(filter: $filter, limit: $limit, skip: $skip, sort: $sort) {
                _id
                firstName
                lastName
                type
            }
        }
	`,
};

export const DistrictQueries = ( value ) => {
	return queries[ value ];
};