import {useQuery} from '@apollo/client';
import React from 'react';
import {usersQueries} from '../pages/Users/actions/Users.queries';

export const UsersFindOneById = ({ children, id }) => {
	const { data, loading, error } = useQuery(usersQueries('UsersFindMany'), {
		variables: {
			filter: {_id: id},
		},
		fetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-600 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-600 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.UsersFindMany || data.UsersFindMany.length === 0) return <p className={'font-mona f-600 text-white p-3'}>No user found</p>;

	const user = data.UsersFindMany[0];

	return children(user);
};