import gql from 'graphql-tag';

const queries = {
	SchoolCodeFindMany: gql`
        query SchoolCodeFindMany($filter: FilterFindManyschoolcodeInput, $skip: Int, $limit: Int, $sort: SortFindManyschoolcodeInput){
            SchoolCodeFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                _id
                code
                curriculums
                classes
                district
                school
                limit
                expirationDate
                deletedAt
                createdAt
                updatedAt
            }
        }
    `,
	SchoolCodeFindOne: gql`
        query SchoolCodeFindOne($filter: FilterFindOneschoolcodeInput, $skip: Int, $sort: SortFindOneschoolcodeInput){
            SchoolCodeFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                mini
                code
                curriculums
                classes
                district
                school
                limit
                expirationDate
                deletedAt
                createdAt
                updatedAt
            }
        }
    `
};

export const SchoolCodeQueries = ( value ) => {
	return queries[value]; 
};