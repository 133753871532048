import {query} from '../actions/queries';
import {useQuery} from '@apollo/client';
import {CheckInQueries} from '../pages/CheckIn/actions/CheckIn.queries';
import PropTypes from 'prop-types';
import {coursesQueries} from '../pages/Courses/actions/Courses.queries';
// questionId:string - we receive the question ID to search inside the questions query and find the class ID,
// if the class exists, therefore we will try to find the user through the UsersFindOne Query.
export const FindManagerInCheckInQuestions = ({ groupId, questionId }) => {
	const {
		data: groupData,
		loading: groupLoading,
		error: groupError,
	} = useQuery(CheckInQueries('groupFindMany'), {
		variables: { filter: { _id: groupId } },
		fetchPolicy: 'network-only',
		skip: !groupId,
	});

	const {
		data: questionData,
		loading: questionLoading,
		error: questionError,
	} = useQuery(CheckInQueries('checkInQuestionFindOne'), {
		variables: { filter: { _id: questionId } },
		fetchPolicy: 'network-only',
		skip: !questionId,
	});

	const classId = questionData?.CheckInQuestionFindOne?.class;
	const {
		data: classData,
		loading: classLoading,
		error: classError,
	} = useQuery(query('classesAdminFindOne'), {
		variables: { id: classId },
		fetchPolicy: 'network-only',
		skip: !classId,
	});

	const curriculumId = classData?.ClassesAdminFindOne?.curriculum;

	const {
		data: curriculumData,
		loading: curriculumLoading,
		error: curriculumError,
	} = useQuery(coursesQueries('CurriculumsFindOne'), {
		variables: { id: curriculumId },
		fetchPolicy: 'network-only',
		skip: !curriculumId,
	});

	const managerIdFromGroup = groupData?.GroupFindMany?.[0]?.manager;
	const managerIdFromQuestion = curriculumData?.CurriculumsFindOne?.teacher;
	let test=null;
	if(managerIdFromGroup){
		test=managerIdFromGroup;
	}
	let managerId = groupId ? managerIdFromQuestion : managerIdFromGroup ;

	if( test ){
		managerId = test;
	}

	const {
		data: userData,
		loading: userLoading,
		error: userError,
	} = useQuery(query('usersFindOne'), {
		variables: { id: managerId },
		fetchPolicy: 'network-only',
		skip: !managerId,
	});

	if (
		groupLoading ||
		questionLoading ||
		classLoading ||
		curriculumLoading ||
		userLoading
	) {
		return null;
	}

	if (groupError || questionError || classError || curriculumError || userError) {
		return 'Error fetching data';
	}

	if (!managerId || !userData?.UsersFindOne) {
		return 'Teacher not found';
	}

	const { firstName, lastName } = userData.UsersFindOne;
	return `${firstName} ${lastName}`;
};

FindManagerInCheckInQuestions.propTypes = {
	groupId: PropTypes.string,
	questionId: PropTypes.string,
};

FindManagerInCheckInQuestions.defaultProps = {
	groupId: null,
	questionId: null,
};