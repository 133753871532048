import {useQuery} from '@apollo/client';
import React from 'react';
import {coursesQueries} from '../pages/Courses/actions/Courses.queries';

export const CurriculumFindMany = ({children}) => {
	const {data, loading, error} = useQuery(coursesQueries('curriculumsFindMany'),{
		variables: {
			sort: 'CREATEDAT_ASC'
		},
		fetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.CurriculumsFindMany)
		return <p className={'font-mona f-900 text-white p-3'}>Curriculum not found</p>;

	return children(data.CurriculumsFindMany);
};