import styled from 'styled-components';

export const LabelVideoContainer = styled.label`
    align-items: stretch;
    background-color: rgba(0, 0, 0, 0.00);
    border: 0 solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    list-style: none;
    margin: 0;
    min-height: 0;
    min-width: 0;
    padding: 0;
    position: relative;
    text-decoration: none;
    z-index: 0;
`;