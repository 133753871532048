import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

// questions array of {question:string, points :number} objects
// onChangeQuestionsArray : update father function args : {id:string, newQuestions : string[]}
export const useQuestionsLayout =({questions,onChangeQuestionsArray})=>{
	const questionsIds =questions.map(q => q.question);

	// findQuestions query
	const { data:dataQuestions, loading: loadingQuestions, error:errorQuestions } = useQuery(query('questionsFindMany'), {
		variables: {
			filter: {
			},
		}
	});

	// get a {points:number,question:idString} object
	const onUpdateQuestion= async(question)=>{
		const editedIndex = questions.indexOf( questions.filter(q=>q.question === question.question)[0] );
		const newQuestions = questions;
		newQuestions[editedIndex].points=question.points;
		onChangeQuestionsArray && await onChangeQuestionsArray(newQuestions);
	};
	// get a {points:number,question:idString} object
	const onCreateQuestion = async (question)=>{
		const newQuestions = [
			...questions,
			question
		];
		onChangeQuestionsArray && await onChangeQuestionsArray(newQuestions);
	};

	const onDeleteQuestion = async(id) =>{
		const deletedId = id;
		const newQuestions =questions.filter(q=>q.question !==deletedId);
		try {
			onChangeQuestionsArray && await onChangeQuestionsArray(newQuestions);
		} catch (error) {
			console.error('Error creating QuestionsView', error);
		}
	};

	const onResortQuestions = async(sourceIndex,destinationIndex) => {
		const newQuestionPointsArray = [...questions];

		const [movedItem] = newQuestionPointsArray.splice(sourceIndex, 1);
		newQuestionPointsArray.splice(destinationIndex, 0, movedItem);
		console.log({newArray: newQuestionPointsArray, questions});
		onChangeQuestionsArray && await onChangeQuestionsArray(newQuestionPointsArray);
	};

	const filteredQuestions = dataQuestions ?dataQuestions['QuestionsFindMany'] : [];
	const sortedQuestions = loadingQuestions? [] : questions.map(qp=>filteredQuestions.find( Q => Q._id === qp.question ));
	return {
		questions: sortedQuestions,
		loadingQuestions:loadingQuestions,
		onUpdateQuestion,
		onDeleteQuestion,
		onCreateQuestion,
		onResortQuestions,
	};
};