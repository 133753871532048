import {SchoolCodeEditFormRender} from './SchoolCodeEdit.render';
import {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {SchoolCodeMutations} from '../actions/SchoolCode.mutations';
import {useHistory, useParams} from 'react-router-dom';
import {SchoolCodeQueries} from '../actions/SchoolCode.queries';
import {Loader} from '../../../components';
import {query} from '../../../actions/queries';
import {coursesQueries} from '../../Courses/actions/Courses.queries';

export const SchoolCodeEditLoad = () => {
	const {id} = useParams();
	const isEditForm = !!id;
	const history = useHistory();
	const isFirstRender = useRef(true);
	const [isChanged, setIsChanged] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const {data, loading: queryLoading, error} = useQuery(SchoolCodeQueries('SchoolCodeFindOne'), {
		variables: {filter:{_id: id}},
		skip: !id,
	});
	const [createSchoolCode, { loading: createLoading }] = useMutation(SchoolCodeMutations('SchoolCodeCreateOne'), {
		refetchQueries: ['SchoolCodeFindMany'],
		awaitRefetchQueries: true,
	});

	const [editSchoolCode, { loading: editingLoading }] = useMutation(SchoolCodeMutations('SchoolCodeUpdateOne'), {
		update: (cache, { data: { SchoolCodeUpdateOne } }) => {
			const existingCodes = cache.readQuery({ query: SchoolCodeQueries('SchoolCodeFindMany') });
			const updatedCodes = existingCodes.map((code) =>
				code._id === SchoolCodeUpdateOne._id ? SchoolCodeUpdateOne : code
			);
			cache.writeQuery({ query: SchoolCodeQueries('SchoolCodeFindMany'), data: updatedCodes });
		},
	});

	const [ SchoolCodeDeleteOne ] = useMutation(SchoolCodeMutations('SchoolCodeDeleteOne'), {
		refetchQueries: ['SchoolCodeFindMany'],
		awaitRefetchQueries: true
	});

	const currentSchoolCode = data?.SchoolCodeFindOne;

	const [formState, setFormState] = useState();

	const [localClasses, setLocalClasses] = useState([]);
	const [localCurriculums, setLocalCurriculums] = useState([]);

	useEffect(() => {

		if (currentSchoolCode) {
			setLocalClasses(currentSchoolCode.classes || []);
			setLocalCurriculums(currentSchoolCode.curriculums || []);
			setFormState({
				code: currentSchoolCode.code || '',
				mini: !!currentSchoolCode.mini,
				district: currentSchoolCode.district || '',
				school: currentSchoolCode.school || '',
				limit: currentSchoolCode.limit || 0,
				expirationDate: currentSchoolCode.expirationDate || '',
			});
		}
		if (isEditForm) {
			isFirstRender.current = false;
			setIsChanged(true);
		}

	}, [currentSchoolCode]);

	useEffect(()=>{
		!isFirstRender.current && setIsChanged(true);
		formState && (isFirstRender.current=false);
	},[formState]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({
			...prev,
			[name]: name === 'limit' ? parseFloat(value) : value,
		}));
		setIsChanged(true);
	};

	const handleAddClass = (classId) => {
		setLocalClasses((prevClasses) => [...prevClasses, classId]);
		setIsChanged(true);
	};

	const handleRemoveClass = (classId) => {
		setLocalClasses((prevClasses) => prevClasses.filter((id) => id !== classId));
		setIsChanged(true);
	};

	const handleAddCurriculum = (curriculumId) => {
		setLocalCurriculums((prevCurriculums) => [...prevCurriculums, curriculumId]);
		setIsChanged(true);
	};

	const handleRemoveCurriculum = (curriculumId) => {
		setLocalCurriculums((prevCurriculums) => prevCurriculums.filter((id) => id !== curriculumId));
		setIsChanged(true);
	};

	const handleCheckboxChange = (e) => {
		const {name, checked} =e.target;
		setFormState((prev) => ({ ...prev, [name]: checked }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const record = {
				...formState,
				limit: isNaN(formState.limit) ? 0 : formState.limit,
				classes: localClasses,
				curriculums: localCurriculums,
			};

			if (id) {
				await editSchoolCode({ variables: { _id: id, record } });
			} else {
				await createSchoolCode({ variables: { record } });
			}

			setIsChanged(false);
		} catch (error) {
			console.error('Error creating or updating SchoolCode: ', error);
		} finally {
			history.push('/school-codes/');
		}
	};

	const handleDelete = async () => {
		try {
			await SchoolCodeDeleteOne({ variables: { schoolCodeId: id } });
			setIsDeleteModalOpen(false);
			history.push('/school-codes/');
		} catch (error) {
			console.error('Error deleting SchoolCode: ', error);
		}
	};

	if (queryLoading || createLoading || (isEditForm && !formState)) {
		return <Loader />;
	}

	if (error) {
		return <span className='font-mona f-600 text-center text-red-500 p-3'>Failed to load school code details. Please try again.</span>;
	}

	return (
		<SchoolCodeEditFormRender
			edit={isEditForm}
			isLoading={createLoading || editingLoading}
			formState={formState}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
			handleAddCurriculum={handleAddCurriculum}
			handleRemoveCurriculum={handleRemoveCurriculum}
			handleAddClass={handleAddClass}
			handleRemoveClass={handleRemoveClass}
			isChanged={isChanged}
			history={history}
			localClasses={localClasses}
			localCurriculums={localCurriculums}
			handleDelete={handleDelete}
			isDeleteModalOpen={isDeleteModalOpen}
			setIsDeleteModalOpen={setIsDeleteModalOpen}
			handleCheckboxChange={handleCheckboxChange}
		/>
	);
};