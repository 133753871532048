import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {coursesQueries} from '../pages/Courses/actions/Courses.queries';

export const CurriculumsFindManyByArrayIds = ({arrayOfCurriculums , children}) =>{
	const { data, loading, error } = useQuery(coursesQueries('curriculumsFindMany'), {
		variables: {
			filter: { },
			sort: 'CREATEDAT_ASC',
		},
		fetchPolicy: 'network-only',
		skip: !arrayOfCurriculums || arrayOfCurriculums.length===0,
	});
	if (loading || error) return null;

	const curriculums = data?.CurriculumsFindMany || [];
	return children(curriculums);
};

CurriculumsFindManyByArrayIds.prototype = {
	arrayOfCurriculums: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.func.isRequired,
};

CurriculumsFindManyByArrayIds.defaultProps = {
	arrayOfCurriculums: [],
};