import PropTypes from 'prop-types';
import Input from '../../../components/ui/Input';
import {DistrictFindMany, SchoolFindMany, SchoolFindManyByOrganization} from '../../../hoc';
import {FindCurriculumsInSelector} from '../components/FindCurriculumsInSelector';
import {FIndClassesInSelector} from '../components/FIndClassesInSelector';
import {ArrowIndicatorIcon} from '../../../icons';
import React, {useState} from 'react';
import DeleteConfirmationModal from '../../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';
import {format} from 'date-fns';
import {CheckBox} from '../../../components';

export const SchoolCodeEditFormRender = ({
											 edit,
											 formState,
											 handleChange,
											 handleSubmit,
											 isLoading,
											 handleAddCurriculum,
											 handleRemoveCurriculum,
											 handleAddClass,
											 handleRemoveClass,
											 isChanged,
											 history,
											 localClasses,
											 localCurriculums,
											 handleCheckboxChange,
	handleDelete, isDeleteModalOpen, setIsDeleteModalOpen
										 }) => {
	const [districtId, setDistrictId] = useState('');
	console.log({
		formState,
		expDate: formState?.expirationDate &&format(new Date(formState?.expirationDate), 'yyyy-MM-dd')
	});
	console.log({formState,isChanged});

	return (
		<div className={'w-600px flex flex-col w-full pb-2'}>
			<div
				className={'flex gap-1 justify-start align-center'}
				onClick={() => history.push('/school-codes/')}
			>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>
					Back
				</h2>
			</div>
			<h2 className={'font-sans text-white f-500 text-2xl mb-5'}>
				{edit ? 'Edit School Code' : 'Create School Code'}
			</h2>
			<div
				className={'flex rounded gap-2 justify-center align-center'}
				style={{ border: '1px solid rgba(52, 59, 79, 1)', background: 'rgba(11, 23, 57, 1)' }}
			>
				<form className={'mt-5 p-5 w-100'} onSubmit={handleSubmit}>
					<Input label={'Code'} type='text' name='code' value={formState?.code} onChange={handleChange} />
					{/* District / Organization Selector */}
					<div className={'mt-4 mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>
							Organization
						</label>
						<DistrictFindMany>
							{(districts) => (
								<select
									name='district'
									value={formState?.district || ''}
									onChange={(e) => {
										const selectedDistrict = districts.find((d) => d._id === e.target.value);
										handleChange(e);
										setDistrictId(selectedDistrict ? selectedDistrict._id : '');
									}}
									className={
										'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'
									}
									required
								>
									<option value=''>Select an Organization</option>
									{districts
									.filter((district) => district._id)
									.map((district) => (
										<option key={district._id} value={district._id}>
											{district.name} - {district.state}, {district.country}
										</option>
									))}
								</select>
							)}
						</DistrictFindMany>
					</div>

					<CheckBox
						name={'mini'}
						defaultChecked={formState?.mini}
						label={'Is mini'}
						onChange={handleCheckboxChange}
					/>

					{/* School Selector */}
					<div className={'mt-4 mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>
							School
						</label>
						<SchoolFindManyByOrganization district={districtId}>
							{(s) => {
								let schools = [...s];
								return(
									<select
										name='school'
										value={formState.school || ''}
										onChange={handleChange}
										className={
											'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'
										}
										required
									>
										<option value=''>Select a School</option>
										{schools
										.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
										.map((school) => (
											<option key={school._id} value={school._id}>
												{school.name}, {school.state}
											</option>
										))}
									</select>
								);
							}}
						</SchoolFindManyByOrganization>
					</div>
					{/* Curriculums Selector */}
					<div className={'mt-4 mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Curriculum</label>
						<FindCurriculumsInSelector
							selectedCurriculums={localCurriculums}
							onAdd={handleAddCurriculum}
							onRemove={handleRemoveCurriculum}
						/>
					</div>
					{/* Classes Selector */}
					<div className={'mt-4 mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Class</label>
						<FIndClassesInSelector
							selectedClasses={localClasses}
							onAdd={handleAddClass}
							onRemove={handleRemoveClass}
						/>
					</div>
					<Input
						label={'Limit'}
						type='number'
						name='limit'
						value={formState?.limit}
						onChange={handleChange}
						required
					/>
					<Input
						label={'Expiration Date'}
						type='date'
						name='expirationDate'
						value={formState?.expirationDate &&format(new Date(formState?.expirationDate), 'yyyy-MM-dd') }
						formt
						onChange={handleChange}
					/>
					<div className={'flex flex-col align-center justify-center center mt-5'}>
						<button
							type='submit'
							className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
								isLoading || !isChanged
									? 'opacity-50 cursor-not-allowed'
									: 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							disabled={isLoading || !isChanged }
						>
							{isLoading ? (edit ? 'Updating...' : 'Creating...') : edit ? 'Update' : 'Create'}
						</button>
						{ edit ? (
							<button
								type='button'
								onClick={() => setIsDeleteModalOpen(true)}
								className={'font-mono max-w-2xl mb-4 px-4 py-2 mt-4 font-medium text-white bg-red-600 rounded hover:bg-red-700'}
							>
								Delete
							</button>
						) : null }
					</div>
				</form>
				<DeleteConfirmationModal
					isOpen={isDeleteModalOpen}
					setIsOpen={setIsDeleteModalOpen}
					onDelete={handleDelete}
					itemName={formState?.code}
				/>
			</div>
		</div>
	);
};

SchoolCodeEditFormRender.propTypes = {
	edit: PropTypes.bool.isRequired,
	formState: PropTypes.shape({
		code: PropTypes.string.isRequired,
		mini: PropTypes.bool,
		district: PropTypes.string.isRequired,
		school: PropTypes.string.isRequired,
		limit: PropTypes.number.isRequired,
		expirationDate: PropTypes.string,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleAddCurriculum: PropTypes.func.isRequired,
	handleRemoveCurriculum: PropTypes.func.isRequired,
	handleAddClass: PropTypes.func.isRequired,
	handleRemoveClass: PropTypes.func.isRequired,
	isChanged: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	localClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
	localCurriculums: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleDelete: PropTypes.func.isRequired,
	isDeleteModalOpen: PropTypes.bool.isRequired,
	setIsDeleteModalOpen: PropTypes.func.isRequired,
};