import {useQuery} from '@apollo/client';
import {CheckInQueries} from '../pages/CheckIn/actions/CheckIn.queries';
import {query} from '../actions/queries';
import PropTypes from 'prop-types';
// groupId:string - we receive the group ID to search inside groups query and find the Manager ID,
// and then find the UserName inside User´s Query.
export const FindManagerByGroupId = ({ groupId }) => {
	const {
		data: groupData,
		loading: groupLoading,
		error: groupError,
	} = useQuery(CheckInQueries('groupFindOne'), {
		variables: { filter: { _id: groupId } },
		fetchPolicy: 'network-only',
	});


	if (groupLoading) return null;
	if (groupError || !groupData?.GroupFindOne || !groupData?.GroupFindOne?.managerObj) return 'Teacher not found';
	
	return `${groupData.GroupFindOne.managerObj.firstName} ${groupData.GroupFindOne.managerObj.lastName}`;
};

FindManagerByGroupId.propTypes = {
	groupId: PropTypes.string
};