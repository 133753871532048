import {CreateSparkLibraryFormRender} from './CreateSparkLibraryForm.render';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {useState} from 'react';
import {sparkLibrariesMutations} from '../../actions/SparkLibraries.mutations';
import {uploadSparkLibraryCover} from '../../../../actions/restactions';
import {UploadSparkLibraryClosedCaptions} from '../../actions/sparkLibraries.rest';
import {coursesQueries} from '../../../Courses/actions/Courses.queries';

export const CreateSparkLibraryFormRenderLoad = ({isOpenState, currentSparkLibrary, refetch}) =>{

	const { data:curriculumsData, loading: curriculumsLoading } = useQuery(coursesQueries('curriculumsFindMany'));
	const { data:lessonsData, loading:lessonsLoading} = useQuery(query('classesAdminFindMany'));
	const [cover,setCover] = useState(null);
	const [coverLoading,setCoverLoading] = useState(false);
	const [uploadCoverError,setUploadCoverError] = useState('');
	const [subtitlesFile, setSubtitlesFile] = useState(null);
	const lessonOptions = lessonsData?.ClassesAdminFindMany?.map((lesson)=>(({value:lesson._id,label:lesson.title}))) || [];
	const curriculumsOptions = curriculumsData?.CurriculumsFindMany?.map((curriculum)=>(({value:curriculum._id,label:curriculum.title}))) || [];
	// mutation
	const [createSparkLibrary,{loading : createLoading,error : createError}] = useMutation(sparkLibrariesMutations('createOne'),{
		refetchQueries:['sparkLibraryFindMany']
	});
	const [updateSparkLibrary,{loading : updateLoading,error : updateError}]  = useMutation(sparkLibrariesMutations('updateOne'),{
		refetchQueries:['sparkLibraryFindMany']
	});

	const defaultValues = {
		title : currentSparkLibrary?.title,
		slug: currentSparkLibrary?.slug,
		lesson: currentSparkLibrary?.lesson,
		curriculum: currentSparkLibrary?.curriculum,
		description: currentSparkLibrary?.description,
		// article
		articleTitle: currentSparkLibrary?.articleTitle,
		articleBody: currentSparkLibrary?.articleBody,
		// take away
		takeAwayLabel: currentSparkLibrary?.takeAwayLabel,
		takeAwayDescription: currentSparkLibrary?.takeAwayDescription,
	};

	const [formData,setFormData] = useState({...defaultValues});

	const updateForm =(name,value)=>{
		setFormData(prev=>({
			...prev,
			[name]:value
		}));
	};
	
	const handleOnChangeFile = ({target})=>{
		setCover(target.files[0]);
		if (!!currentSparkLibrary){ //edit
			updateCover(target.files[0],currentSparkLibrary._id).then();
		} else if (target.name === 'subtitles') {
			setSubtitlesFile(target.files[0]);
		}
	};

	const handleOnChange= ({target}) => {
		updateForm(target.name,target.value);
	};

	
	//actions

	const updateCover = async (cover,id)=>{
		setCoverLoading(true);
		uploadSparkLibraryCover(cover,id)
		.then(refetch)
		.catch((e)=>setUploadCoverError(e))
		.finally(()=>{setCoverLoading(false);});
	};


	const uploadSparkLibrarySubtitles = async (file, id) => {
		try {
			await UploadSparkLibraryClosedCaptions(file, id, true, 'en');
		} catch (error) {
			console.error('Error uploading subtitles:', error);
		}
	};

	const handleCreateSparkLibrary = async()=>{
		try {
			const res = await createSparkLibrary({variables:{record: {...formData}}});
			if (cover){
				await updateCover(cover,res.data.SparkLibraryCreateOne.recordId);
				const setIsOpen = isOpenState[1];
				setIsOpen(false);
			}
			if (subtitlesFile) {
				await uploadSparkLibrarySubtitles(subtitlesFile, res.data.SparkLibraryCreateOne.recordId);
			}
		}
		catch (e){
			console.log({e});
		}
	};
	

	const handleEditSparkLibrary = async ()=>{
		console.log({id:currentSparkLibrary._id});
		try {
			await updateSparkLibrary({variables:{
				record: {...formData},
				id:currentSparkLibrary._id
			}});

			if (subtitlesFile) {
				await uploadSparkLibrarySubtitles(subtitlesFile, currentSparkLibrary._id);
			}

			const setIsOpen = isOpenState[1];
			setIsOpen(false);
		}
		catch (e){
			console.log({e});
		}
	};

	const onSubmit = async (e)=>{
		e.preventDefault();
		if (!currentSparkLibrary){
			handleCreateSparkLibrary().then();
		}
		else{
			handleEditSparkLibrary().then();
		}

	};

	return (
		<CreateSparkLibraryFormRender
			isEdition = {!!currentSparkLibrary}
			isLoading={createLoading || updateLoading }
			errors={{submit:createError, cover:uploadCoverError}}
			isOpenState={isOpenState}
			curriculumsOptions={curriculumsOptions||[]}
			lessonsOptions={lessonOptions}
			values={{...formData, cover:currentSparkLibrary?.cover?.url}}
			onSubmit={onSubmit}
			handleOnChange={handleOnChange}
			handleOnChangeFile={handleOnChangeFile}
		/>
	);
};