import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {
	uploadCoursesCover,
	UploadCoursesTrailerClosedCaptions,
	UploadCurriculumTrailer
} from '../../../Courses/actions/Coures.rest';
import {removeTypename as removeTypenameFunction} from '../../../../tools';
import {InfoRender} from './Info.render';
import {coursesMutations} from '../../../Courses/actions/Courses.mutations';
import {coursesQueries} from '../../../Courses/actions/Courses.queries';

export const InfoLoad = () => {
	const { id } = useParams();
	const history = useHistory();
	const { loading, error, data , refetch} = useQuery(coursesQueries('CurriculumsFindOne'), {
		variables: { id },
		nextFetchPolicy: true
	});
	const { data: lifeSkillsData } = useQuery(query('lifeSkillsFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const { data: skillSetsData } = useQuery(query('skillSetFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const { data: themesData } = useQuery(query('ThemeFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const [selectedLifeSkills, setSelectedLifeSkills] = useState([]);
	const [selectedSkillSets, setSelectedSkillSets] = useState([]);
	const [selectedThemes, setSelectedThemes] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [trailer, setTrailer] = useState(null);
	const [trailerPreview, setTrailerPreview] = useState(null);

	// changes
	const [changed,setChanged] = useState(false);
	const [isUploadingFile,setIsUploadingFile] = useState(false);

	
	const [formState, setFormState] = useState( undefined);
	const [subtitleState, setSubtitleState] = useState({
		file: null,
		error: '',
		loading: false,
	});

	const curriculum = data?.CurriculumsFindOne;
	useEffect(() => {
		if (curriculum) {
			const formData = {...curriculum};

			setFormState({
				title:formData.title,
				description:formData.description,
				teacher:formData.teacher,

				mini:formData.mini,
				active:formData.active,
				donation:formData.donation,

				courseDuration:formData.courseDuration,
				slug:formData.slug,
				lessonLength:formData.lessonLength,
				totalLessons:formData.totalLessons,

				animalCategory:formData.animalCategory,
				leaderBio:formData.leaderBio,
				learningGoal:formData.learningGoal,

			});
			if (lifeSkillsData) {
				const mappedLifeSkills = curriculum.lifeSkill.map(skillId =>
					lifeSkillsData.LifeSkillFindMany.find(skill => skill._id === skillId)
				);
				setSelectedLifeSkills(mappedLifeSkills);
			}
			if (skillSetsData) {
				const mappedSkillSets = curriculum.skillSet.map(skillSetId =>
					skillSetsData.SkillSetFindMany.find(skillSet => skillSet._id === skillSetId)
				);
				setSelectedSkillSets(mappedSkillSets);
			}
			if (themesData) {
				const mappedThemes = curriculum.theme.map(themeId =>
					themesData.ThemeFindMany.find(theme => theme._id === themeId)
				);
				setSelectedThemes(mappedThemes);
			}
			if (curriculum.trailer) {
				setTrailerPreview(curriculum.trailer.url);
			}
		}
	}, [data, lifeSkillsData, skillSetsData, themesData]);

	const [updateCourse, { loading: isLoading, error: isError }] = useMutation(coursesMutations('curriculumsUpdateOne'), {
		refetchQueries: [
			{ query: query('curriculumsFindMany') }
		],

		awaitRefetchQueries: true
	});

	const removeTypename = (obj) => removeTypenameFunction(obj);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		const inputValue = type === 'checkbox' ? checked : value;
		setFormState((prev) => ({ ...prev, [name]: inputValue }));
		
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setChanged(true);
	};

	const handleTrailerChange = (e) => {
		const file = e.target.files[0];
		setChanged(true);
		if (file) {
			setTrailer(file);
			setTrailerPreview(URL.createObjectURL(file));
		} else {
			setTrailer(null);
			setTrailerPreview(null);
		}
		
	};

	const handleLifeSkillsChange = (updatedSkills) => {
		setSelectedLifeSkills(updatedSkills);
		setFormState((prev) => ({
			...prev,
			lifeSkill: updatedSkills.map(skill => skill._id),
		}));
		setChanged(true);
	};

	const handleSkillSetsChange = (updatedSkills) => {
		setSelectedSkillSets(updatedSkills);
		setFormState((prev) => ({
			...prev,
			skillSet: updatedSkills.map(skill => skill._id),
		}));
		setChanged(true);
	};

	const handleThemesChange = (updatedThemes) => {
		setSelectedThemes(updatedThemes);
		setFormState((prev) => ({
			...prev,
			theme: updatedThemes.map(theme => theme._id),
		}));
		setChanged(true);
	};

	const handleCheckboxChange = (e) => {
		const {name, checked} =e.target;
		setFormState((prev) => ({ ...prev, [name]: checked }));
		setChanged(true);
	};


	const handleSubmit = async (e) => {
		e.preventDefault();
		const updateFiles = selectedFile || trailer || subtitleState.file;
		updateFiles && setIsUploadingFile(true);
		try {
			const cleanedFormState = removeTypename(formState);
			const { _id, createdAt, updatedAt, ...record } = cleanedFormState;

			const lifeSkillIds = selectedLifeSkills.map(skill => skill?._id).filter(Boolean);
			const skillSetIds = selectedSkillSets.map(skill => skill?._id).filter(Boolean);
			const themeIds = selectedThemes.map(theme => theme?._id).filter(Boolean);

			await Promise.all([
				updateCourse({
					variables: {
						_id: id ,
						record: {
							...record,
							totalLessons:formState.totalLessons && parseInt(formState.totalLessons),
							lifeSkill: lifeSkillIds,
							skillSet: skillSetIds,
							theme: themeIds,
						}
					},
				}),
					
				// upload files
				selectedFile && uploadCoursesCover(selectedFile, id),
				trailer && await UploadCurriculumTrailer(trailer, id),
				subtitleState.file && await UploadCoursesTrailerClosedCaptions(subtitleState.file, id, true, formState.language),
			]);

			// upload files
			if (updateFiles) {
				await refetch();
			}


			history.push('/courses');
		} catch (error) {
			console.error('Error updating course:', error);
		} finally {
			console.log('Course Edited Created');
			history.push('/courses');
			setIsUploadingFile(false);
		}
	};

	if (error) return <span className={'font-mona f-900 text-white p-3'}>Error: {error.message}</span>;
	if (loading || !formState) return <span className={'font-mona f-900 text-white p-3'}>Loading...</span>;

	return (
		<InfoRender
			curriculum={curriculum}
			formState={formState}
			selectedLifeSkills={selectedLifeSkills}
			selectedSkillSets={selectedSkillSets}
			selectedThemes={selectedThemes}
			trailerPreview={trailer ? URL.createObjectURL(trailer) : trailerPreview || ''}
			isLoading={isLoading || isUploadingFile}
			isError={isError}
			refetch={refetch}
			changed={changed}

			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleTrailerChange={handleTrailerChange}
			handleLifeSkillsChange={handleLifeSkillsChange}
			handleSkillSetsChange={handleSkillSetsChange}
			handleThemesChange={handleThemesChange}
			handleSubmit={handleSubmit}
			handleCheckboxChange={handleCheckboxChange}
		/>
	);
};