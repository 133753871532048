import gql from 'graphql-tag';

const queries = {
	UsersFindMany: gql`
        query UsersFindMany($filter: userInput) {
            UsersFindMany(filter: $filter) {
                _id
                firstName
                lastName
                userName
                tag
                bio
                birthday
                email
                profilePicture {
                    type
                    url
                    title
                }
                points
                type
                lastLogin
                organization
                cleverId
                classLinkId
                cleverSync
                classLinkSync
                deedlyAuthenticationToken
                deedlyUserId
                createdAt
                updatedAt
            }
        }
	`,
	usersFindOne:gql`
        query UsersFindOne($id:String!){
            UsersFindOne(_id:$id){
                _id
                firstName
                lastName
                userName
                tag
                bio
                email
                profilePicture {
                    url
                    type
                }
                type
                lastLogin
                updatedAt
                createdAt
            }
        }`,
	userTypesFindMany:gql`
        query UserTypesFindMany($filter: FilterFindManyusertypesInput, $sort: SortFindManyusertypesInput){
            UserTypesFindMany(filter: $filter, sort:$sort){
                _id
                identifier
                label
            }
        }`,
};

export const usersQueries = ( value ) => {
	return queries[ value ];
};