import React,{useEffect,useState} from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import {useMutation,useQuery} from '@apollo/client';
import {mutation} from '../../actions/mutations';
import {useParams} from 'react-router-dom';
import {query} from '../../actions/queries';
import {Responses} from '../question/Responses';
import {languages,deleteTypeMutation} from '../../tools';
import {LanguageForm} from './LanguageForm';
import {TextArea} from '../ui/TextArea';
import {CKEditorInput} from '../ui/CKEditorInput/CKEditorInput';

export const QuestionForm=()=>{
	const {id}=useParams();
	const [form,setForm]=useState({responses:[],rightAnswers:[],language:{spanish:{},english:{},french:{}}});
	const [saving, setSaving] = useState(false);
	const [createAr]=useMutation(mutation(id==='new' ? 'questionsCreateOne' : 'questionsUpdateOne'));
	
	const {data,loading,error}=useQuery(
		query('questionsFindOne'),
		{
			variables:{id},
		}
	);
	
	
	useEffect(()=>{
		const question = data?.QuestionsFindOne && data?.QuestionsFindOne[0];
		console.log({ question,  });
		if(!loading && !error && data && question) {
			setForm({
				label:question.label,
				labelHtml:question.labelHtml,
				identifier:question.identifier,
				responses:question.responsesObj,
				rightAnswers:question.rightAnswersObj,
				language : question.language || {spanish:{},english:{},french:{}}
			});
		}
	},[data,loading,error]);
	
	const handlerForm=({target})=>{
		let {value,name}=target;
		setForm({...form,[name]:value});
	};
	
	const handlerResponses=({values,name})=>{
		setForm({...form,[name]:values});
	};
	
	const send=async()=>{
		setSaving(true);
		
		let data={
			...deleteTypeMutation(form),
			responses:form.responses.map(i=>i._id),
			rightAnswers:form.rightAnswers.map(i=>i._id)
		};
		
		if(Object.keys(data.language.spanish).length === 0 && Object.keys(data.language.english).length === 0 && Object.keys(data.language.french).length === 0 )
			delete data.language;
			
		
		let variables={record:data};
		if(id!=='new')
			variables.id=id;
		
		await createAr({
			variables
		})
		.then(({_})=>{
			setSaving(false);
		})
		.catch(_=>{
			setSaving(false);
		});
	};
	
	return (
		<div className='w-100 h-100'>
			<TextArea
				label={'label'}
				name={'label'}
				value={form.label}
				className={'mb-4'}
				onChange={handlerForm}
			/>

			<CKEditorInput
				label={'labelHtml'}
				value={form.labelHtml || ''}
				name={'labelHtml'}
				onChange={handlerForm}
			/>

			<Input
				label={'identifier'}
				name={'identifier'}
				value={form.identifier}
				className={'mb-4'}
				onChange={handlerForm}
			/>
			
			<Responses
				label={'Responses'}
				values={form.responses}
				name={'responses'}
				onChange={handlerResponses}
			/>
			
			<Responses
				label={'Right Answers'}
				values={[form.rightAnswers]}
				name={'rightAnswers'}
				onChange={handlerResponses}
			/>
			
			{languages.map(i=>
				<LanguageForm
					key={i}
					setForm={setForm}
					form={form}
					language={i}
				/>
			)}
			
			<Button onClick={send}>
				{saving ? 'Saving...' : 'Save'}
			</Button>
		</div>
	);
};